import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const ArrowButton = props => {
  return (
    <div
      className="button-wrapper"
      data-aos="fade-up"
      data-aos-delay={props.aosDelay}
    >
      <Link to="/portfolio" className="button arrow-button hoverable">
        {props.leftArrow && (
          <>
            <span className="arrow left--arrow"></span>
            <span>{props.buttonText}</span>
          </>
        )}
        {props.rightArrow && (
          <>
            <span>{props.buttonText}</span>
            <span className="arrow right--arrow hoverable"></span>
          </>
        )}
      </Link>
    </div>
  );
};

export default ArrowButton;
