import React from "react";

// import components
import { Navbar, PortfolioGrid, Contact, Footer } from '../Components'

import SideProjectsGrid from '../Components/SideProjectsGrid'

function Home() {
  return (
    <>
      <Navbar />
      <PortfolioGrid />
			<SideProjectsGrid />
			<Contact />
			<Footer />
    </>
  );
}

export default Home;
