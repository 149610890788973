import React from "react";
import QuickLinks from "../MicroComponents/QuickLinks";
import { Link } from "react-router-dom";

import "./PortfolioCard.scss";

const PortfolioCard = (props) => {
	const sideProjects = props.sideProjects;
	
  if (sideProjects) {
    return (
      <div data-aos="fade-up" data-aos-delay="200">
        <div className="card">

            <div className="card-image">
              <img className="image" data-src={props.image} alt={props.alt} />
            </div>

          <QuickLinks
						singleLink
            link={props.quickLinkWebsite}
          />

          <div className="meta">
            <h5 className="meta-title">{props.name}</h5>
						<p className="meta-description">{props.description}</p>
            <div className="flex">
              <div className="badges-group">
                <p className="badge platform">{props.platform}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div data-aos="fade-up" data-aos-delay="200">
      <div className="card">
        <Link to={props.linkOnImage}>
          <div className="card-image">
            <img className="image" data-src={props.image} alt={props.alt} />
          </div>
        </Link>

        <QuickLinks to={props.quickLinkDetails} link={props.quickLinkWebsite} />

        <div className="meta">
          <h5 className="meta-title">{props.name}</h5>
          <div className="flex">
            <div className="badges-group">
              <p className="badge platform">{props.platform}</p>
              <p className="badge workScope">{props.workScope}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
