import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./styles.scss";

import { NavButton } from '../NavButton'

const Navbar = () => {
  return (
		<section className="has-background-white">
				<div className="container header">
					<nav className="navbar" role="navigation" aria-label="main navigation">
						<div className="navbar-brand">
							<Link to="/">
								<p className="logo">AC</p>
							</Link>
						</div>

						<div id="navbarBasic" className="navbar-menu">
							<div className="navbar-end">
								<NavLink exact={true} className="nav-link" to="/">
									<NavButton title="Home" />
								</NavLink>
								<NavLink className="nav-link" to="/portfolio">
									<NavButton title="Portfolio" />
								</NavLink>
							</div>
						</div>
					</nav>
				</div>
		</section>
  );
};

export default Navbar;
