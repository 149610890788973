import React from 'react'
import './styles.scss'

export const NavButton = props => {
	return (
		<span className="underline-from-left">
			{props.title}
		</span>
	)
}
