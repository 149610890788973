import React from "react";

import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer has-background-blue-light">
			<div className="container">
				<div className="content">
					<a href="/" className="">
							<p className="logo">AC</p>
					</a>
					<div className="footer--right-content">
						{/* <a href="mailto:hello@webgorilla.co">Contact</a> */}
						<p>Copyright @ 2020</p>
					</div>
				</div>
			</div>
    </footer>
  );
};

export default Footer;
