import React from "react";
import "./styles.scss";

// import components
import ArrowButton from "../ArrowButton";

const Hero = (props) => {
  return (
    <section className="section is-small has-background-blue-light hero-section">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-6">
            {props.displayImage && (
              <figure
                className="image"
                data-aos="fade-up"
                data-aos-delay={props.imageAosDelay}
              >
                <img className="hero-image" data-src={props.image} alt="Andrei Cleper - Front End Developer" />
              </figure>
            )}
            <h1 data-aos="fade-up" data-aos-delay={props.titleAosDelay}>
              {props.title}
            </h1>
            <h3
              data-aos="fade-up"
              data-aos-delay={props.subtitleAosDelay}
              className="has-text-weight-light"
            >
              {props.subtitle}
            </h3>
            <ArrowButton
              rightArrow
              buttonText="view portfolio"
              aosDelay={props.arrowButtonAosDelay}
            />
          </div>
          <div className="column is-6">
            <figure
              className="bg-decoration z-index-1"
              aria-hidden="true"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <svg
                className="bg-decoration__svg"
                viewBox="0 0 976 976"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.654297"
                  y="402.157"
                  width="700"
                  height="700"
                  rx="100"
                  transform="rotate(-35 0.654297 452.157)"
                  fill="#1E266D"
                  fillOpacity="0.05"
                />
              </svg>
            </figure>
            <div className="hero-featured">
              <div
                className="hero-featured__item"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <img
                  className="hero-featured__item-image width-80 float-right"
                  data-src="/assets/hero/parkourforschools-crop.png"
									alt="parkour for schools"
                />
              </div>
              <div
                className="hero-featured__item"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <img
                  className="hero-featured__item-image margin-top"
                  data-src="/assets/hero/numbots-pricing-crop.png"
                  alt="numbots"
                />
              </div>
              <div
                className="hero-featured__item"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <img
                  className="hero-featured__item-image"
                  data-src="/assets/hero/mathsrocks-crop.png"
                  alt="maths rocks"
                />
              </div>
              <div
                className="hero-featured__item"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <img
                  className="hero-featured__item-image width-80 margin-top2"
                  data-src="/assets/hero/parkour-fitness-about-crop.png"
                  alt="parkour fitness"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
