import React from "react";
import { Link } from "react-router-dom";
import "./QuickLinks.scss";

const Quicklinks = (props) => {

	if (props.singleLink) {
		return (
			<div className="quick-links">
				<a
					href={props.link}
					className="quicklinks--link"
					target="_blank"
					rel="noopener noreferrer"
					title="visit website"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="link-svg"
						width="48"
						height="48"
						viewBox="0 0 24 24"
						fill="none"
						stroke="#fff"
						strokeWidth="2"
						strokeLinecap="square"
						strokeLinejoin="arcs"
					>
						<g fill="none" fillRule="evenodd">
							<path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8" />
						</g>
					</svg>
					<span className="screen-reader">visit website</span>
				</a>
			</div>
		);
	}

  return (
    <div className="quick-links">
      <Link
        to={props.to}
        className="quicklinks--link"
        title="view project details"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="link-svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
          strokeLinecap="square"
          strokeLinejoin="arcs"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle
            stroke="#006fff"
            strokeWidth="2"
            cx="12"
            cy="12"
            r="3"
          ></circle>
        </svg>
        <span className="screen-reader">view project details</span>
      </Link>
      <a
        href={props.link}
        className="quicklinks--link"
        target="_blank"
        rel="noopener noreferrer"
        title="visit website"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="link-svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="arcs"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8" />
          </g>
        </svg>
        <span className="screen-reader">visit website</span>
      </a>
    </div>
  );
};

export default Quicklinks