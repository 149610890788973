import React from "react";
import "./App.scss";

// router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

// import pages
import Home from "./Pages/Home";
import Portfolio from "./Pages/Portfolio";
import PortfolioSingle from "./Pages/PortfolioSingle";
import PageNotFound from "./Pages/PageNotFound";

// Google analytics import
import ReactGA from 'react-ga';

// AOS library
import AOS from "aos";
import "aos/dist/aos.css";

import ScrollRestoration from './Components/ScrollRestoration'

// AOS initialization
AOS.init({ 
  duration: 400,
	offset: 150,
	easing: 'ease-in-out-quad',
	mirror: false,
  once: true
});

// Google analytics function
const trackingId = "UA-146468457-3";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <>
      <Router>
				<ScrollRestoration/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route
            exact
            path="/portfolio/:portfolioId"
            component={PortfolioSingle}
          />

          <Route exact path="/404" component={PageNotFound} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
