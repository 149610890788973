import React from "react";

// import components
import Navbar from "../Components/Navbar";

// import websites data
import websites from "../Data/websites";

// import components
import ArrowButton from "../Components/ArrowButton";
import PortfolioSingleGrid from "../Components/PortfolioSingleGrid";
import Footer from "../Components/Footer";

const PortfolioSingle = ({ match }) => {
  const {
    params: { portfolioId },
  } = match;

  return (
    <>
      <Navbar />
      <div className="container">
        <ArrowButton leftArrow buttonText="back to portfolio" aosDelay="100" />
      </div>
      <PortfolioSingleGrid>
        <div className="portfolio-sidebar--sticky">
          <h3 data-aos="fade-up" data-aos-delay="200">
            {websites[portfolioId - 1].name}
          </h3>
          <p data-aos="fade-up" data-aos-delay="300">
            {websites[portfolioId - 1].description}
          </p>
					<p className="design-by" data-aos="fade-up" data-aos-delay="400">
						<b>Design By:</b> {websites[portfolioId -1].designBy} 
					</p>
          <div className="action" data-aos="fade-up" data-aos-delay="500">
            <a
              className="button"
              href={websites[portfolioId - 1].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit website
            </a>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="500">
          <figure className="image">
            <img
              src={websites[portfolioId - 1].image}
              alt={websites[portfolioId - 1].alt}
            />
          </figure>
        </div>
      </PortfolioSingleGrid>
      <Footer />
    </>
  );
};

export default PortfolioSingle;
