/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./styles.scss";

// import data
import websites from "../../Data/websites";

// import components
import { Heading, ArrowButton } from "../index";
import PortfolioCard from "../../MicroComponents/PortfolioCard";

const PortfolioGrid = (props) => {
  const listLimit = props.listLimit;

  if (listLimit) {
    return (
      <section className="section has-background-blue-light">
        <div className="container">
          <Heading heading="Portfolio" />

          <div className="portfolio grid">
            {websites.slice(0, 3).map((website, index) => (
              <PortfolioCard
                key={index}
                linkOnImage={`/portfolio/${index + 1}`}
                image={website.image}
                alt={website.alt}
                quickLinkDetails={`/portfolio/${index + 1}`}
                quickLinkWebsite={website.link}
                name={website.name}
                platform={website.platform}
                workScope={website.workScope}
              />
            ))}
          </div>
          <div className="has-text-centered">
            <ArrowButton
              rightArrow
              buttonText="view portfolio"
              aosDelay={props.arrowButtonAosDelay}
            />
          </div>
        </div>
      </section>
    );
  }

  // if no listLimit => return this
  return (
    <section className="section has-background-blue-light">
      <div className="container">
        <Heading
          heading="Selected Projects"
          subheading="Websites made for my clients."
        />

        <div className="portfolio grid">
          {websites.map((website, index) => (
            <PortfolioCard
              key={index}
              linkOnImage={`/portfolio/${index + 1}`}
              image={website.image}
              alt={website.alt}
              quickLinkDetails={`/portfolio/${index + 1}`}
              quickLinkWebsite={website.link}
              name={website.name}
              platform={website.platform}
              workScope={website.workScope}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PortfolioGrid;
