import React from "react";
import "./styles.scss";

import ContactForm from "../ContactForm";
import Modal from "react-modal";

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <section className="section is-medium contact">
        <div className="container">
          <div className="columns is-vcentered is-centered">
            <div
              className="column is-8 has-background-blue-light has-text-centered"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2>Get in Touch.</h2>
              <p>Have a project in mind or just want to say hi?</p>

              <div
                className="button-wrapper aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <button
                  className="button arrow-button contact-button"
                  onClick={this.handleOpenModal}
                >
                  <span>contact</span>
                  <span className="arrow right--arrow hoverable"></span>
                </button>
              </div>

              <Modal
                isOpen={this.state.showModal}
                contentLabel="onRequestClose Example"
                onRequestClose={this.handleCloseModal}
                className="Modal"
                overlayClassName="Overlay"
              >
                <div className="modal-box">
                  <ContactForm />
                  <button className="button close-modal is-small" title="close modal" onClick={this.handleCloseModal}>X</button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const props = {};

export default Contact;
