import React, { useState } from "react";
import "./styles.scss";

const initFormState = {
  email: "",
  name: "",
  message: "",
};

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    ...initFormState,
  });

  const handleFormChange = (e) => {
    e.preventDefault();

    const changedElement = e.target;
    const { name, value } = changedElement;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    // action="/page-2/"
    <form id="form" name="contact" method="post" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <div className="input-details">
        <div>
          <input
            value={formValues.email}
            className="input-field"
            id="form-email"
            type="email"
            name="email"
						onChange={handleFormChange}
						placeholder="Your e-mail"
          />
        </div>

        <div>
          <input
            value={formValues.name}
            className="input-field"
            id="form-name"
            type="text"
            name="name"
						onChange={handleFormChange}
						placeholder="Your name"
          />
        </div>
      </div>
      <div className="input-message">
        <textarea
          value={formValues.message}
          name="message"
          id="form-message"
					onChange={handleFormChange}
					rows="5"
					placeholder="Your message"
        />
      </div>
      <div className="input-action">
        <input className="button submit-btn is-small is-fullwidth" type="submit" name="submit-btn" value="Submit" />
      </div>
    </form>
  );
};

export default ContactForm;
