import React from "react";

import "./styles.scss";

const Heading = (props) => {
  return (
    <div
      className={`heading ${props.class ? props.class : ""}`}
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <h2> {props.heading} </h2>
      <p> {props.subheading} </p>
    </div>
  );
};

export default Heading;
