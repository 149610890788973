import React from "react";
import "./styles.scss";

import Heading from "../Heading";
import PortfolioCard from "../../MicroComponents/PortfolioCard";

import sideProjects from "../../Data/side-projects";

const SideProjectsGrid = () => {
  return (
    <section className="section section-sideprojects is-small">
      <div className="container">
        <Heading
          heading="Side Projects"
          subheading="Websites or Apps made in my spare time."
        />

        <div className="portfolio grid sideprojects">
          {sideProjects.map((sideProject, index) => (
            <PortfolioCard
							key={index}
              sideProjects
              image={sideProject.image}
              alt={sideProject.alt}
              quickLinkWebsite={sideProject.link}
              name={sideProject.name}
              description={sideProject.description}
              platform={sideProject.platform}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SideProjectsGrid;
