import React, { Component } from "react";

// React Carousel
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

// styles
import "./styles.scss";

const reviews = [
  {
    description:
      "Andrei is a great developer, always easy to communicate with and gets the job done. We will definitely collaborate again in the future.",
    name: "Andra Baragan",
    companyName: "Ontrack Agency",
    companyLink: "https://ontrack.agency",
  },
  {
    description:
      "Andrei has just been the best. Super skilled, responsive and very very helpful. We're definitely going to continue using him on a regular basis.",
    name: "Charlotte Miles",
    companyName: "Parkour Generations",
    companyLink: "https://parkourgenerations.com",
  },
  {
    description:
      "Had a great experience working with Andrei for eCommerce and template fixes for our WordPress site. He got the job done quickly and according to our requirements, and suggested improvements to the approach. Would definitely work with him again for similar projects.",
    name: "Matt",
    companyName: "Capsulerie",
    companyLink: "https://capsulerie.com",
  },
  {
    description:
      "Another project completed successfully and on-time with Andrei. He is so reliable and friendly, plus he is able to execute almost anything you ask of him. Will definitely be working with him again.",
    name: "Natalia",
    companyName: "Parkour Fitness",
    companyLink: "https://parkourfitness.com",
  },
];

function ReviewsCarousel(props) {
  return (
    <section className="section is-small reviews">
			<div 
				className="container"  
				data-aos="fade-up"
				data-aos-delay={props.aosDelay}
			>
				<div className="columns is-centered"> 
					<div className="column is-10">
						<Carousel
							dots
							arrows
							// infinite
							autoplay
							animationSpeed={1000}
						>
							{reviews.map((review, index) => (
								<div className="quote-block" key={index}>
									<p className="description qoute quote-smaller">
										{review.description}
									</p>
									<p className="name">{review.name}</p>
									<a
										className="company"
										href={review.companyLink}
										target="_blank"
										rel="noopener noreferrer"
									>
										@{review.companyName}
									</a>
								</div>
							))}
						</Carousel>
					</div>
				</div>
      </div>
    </section>
  );
}

export default ReviewsCarousel;
