import React from "react";
import "./styles.scss";

function SectionDivider(props) {
  return (
    <div
      className="section-divider"
      data-aos="fade-up"
      data-aos-delay={props.aosDelay}
    ></div>
  );
}

export default SectionDivider;
