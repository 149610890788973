const sideProjects = [
	{
    name: "Bookmarked",
    image: "/assets/sideprojects/bookmarked.jpeg",
    alt: "Bookmarked website",
    platform: "ReactJS",
    workScope: "Web Development",
    description: "Handpicked Collections of Tools & Resources",
    finishDate: "November 2019",
    link: "https://Bookmarked.xyz/"
	},
	// {
  //   name: "Meet Andrei",
  //   image: "/assets/sideprojects/meet-andrei.jpeg",
  //   alt: "Meet Andrei website",
  //   platform: "ReactJS",
  //   workScope: "Web Development",
  //   description: "This very website",
  //   finishDate: "July 2020",
  //   link: "https://meet-andrei.netlify.app/"
	// }
]

export default sideProjects