import React from "react";
import "./styles.scss";

const clients = [
  "/Assets/clients/ontrack-agency.png",
  "/Assets/clients/mylittlepanda.png",
  "/Assets/clients/parkourgenerations.jpg",
  "/Assets/clients/maths-circle.svg",
  "/Assets/clients/want-sound.png",
  "/Assets/clients/lbs-office.png",
];

const Clients = (props) => {
  return (
    <section
      className="section is-small clients-section has-background-white"
      data-aos="fade-up"
      data-aos-delay={props.aosDelay}
    >
      <div className="container">
        <div className="clients grid">
          {clients.map((client, index) => (
            <figure className="image" key={index}>
              <img data-src={client} alt="client" key={index} width="140" />
            </figure>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Clients;
