import React from "react";

// import components
import {
  Navbar,
  Hero,
  Clients,
  Services,
  PortfolioGrid,
  Footer,
  Contact,
  ReviewsCarousel,
} from "../Components";

import SectionDivider from '../Components/SectionDivider'

function Home() {
  return (
    <>
      <Navbar />
      <Hero
        displayImage
        image="Assets/personal/andrei2.png"
        title="Front-end Developer"
        subtitle="Helping businesses and agencies achieve high quality websites"
        imageAosDelay="100"
        titleAosDelay="200"
        subtitleAosDelay="300"
        arrowButtonAosDelay="400"
      />
      <ReviewsCarousel aosDelay="300" />
			<SectionDivider aosDelay="300" />
      <Clients aosDelay="300" />
      <PortfolioGrid listLimit />
      <Contact />
      {/* <Services /> */}
      <Footer />
    </>
  );
}

export default Home;
