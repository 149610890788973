const websites = [
	{
    name: "NumBots",
    image: "/assets/product-images/numbots.jpeg",
    alt: "numbots-project",
    platform: "Wordpress",
    workScope: "Design Implementation",
		description: "Design implementation on Wordpress, mobile ready, hero animations with Revolution Slider, CSS animations for some elements and a dynamic pricing table.",
		designBy: "Gary Allen",
		designByLink: "www.google.com",
    finishDate: "June 2019",
    link: "https://numbots.com/"
	},
	{
    name: "Parkour Fitness",
    image: "/assets/product-images/parkourfitness.jpeg",
    alt: "parkour-fitness-project",
    platform: "Shopify",
    workScope: "Design Implementation",
		description: "Design implementation on Shopify, mobile ready, custom page and product templates, Partia.ly payments integration.",
		designBy: "Natalia",
		designByLink: "www.google.com",
    finishDate: "September 2018",
    link: "https://parkour.fitness/"
	},
  {
    name: "Parkour for Schools",
    image: "/assets/product-images/parkourforschools.jpeg",
    alt: "parkour-for-schools-project",
    platform: "Wordpress",
    workScope: "Design Implementation",
		description: "Design implementation on Wordpress, mobile ready, element animations.",
		designBy: "Natalia",
		designByLink: "www.google.com",		
    finishDate: "May 2017",
    link: "https://parkourforschools.com/"
	},	
	{
    name: "Copycat Fragrances",
    image: "/assets/product-images/copycatfragrances.jpeg",
    alt: "Copycat-fragrances-project",
    platform: "Shopify",
    workScope: "Design Implementation",
		description: "Homepage design implementation on Shopify, mobile ready, product page custom templates, apps integrations.",
		designBy: "Oana",
		designByLink: "www.google.com",		
    finishDate: "Jul 2020",
    link: "https://copycatfragrances.co.uk/"
	},
  {
    name: "Pure Indie Records",
    image: "/assets/product-images/pureindierecords.jpeg",
    alt: "pure-indie-records-project",
    platform: "Shopify",
    workScope: "Vinyl Store",
		description: "Shopify store, for vinyl records. Shipping and online payments integrations, currency changer, radio and audio player on products.",
		designBy: "",
		designByLink: "www.google.com",				
    finishDate: "August 2019",
    link: "https://pureindierecords.com/"
  },
  {
    name: "Want Sound",
    image: "/assets/product-images/want-sound.jpeg",
    alt: "want-sound-project",
    platform: "Wordpress",
    workScope: "Rental Portfolio",
		description: "Rental portfolio, based on Wordpress, .",
		designBy: "",
		designByLink: "www.google.com",				
    finishDate: "August 2018",
    link: "https://want-sound.ro/"
  },
];

export default websites;
