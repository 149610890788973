import React from "react";

// import components
import Navbar from "../Components/Navbar";
// import Footer from "../Components/Footer";

const PageNotFound = () => {

  return (
    <>
      <Navbar />
			<center>Page not found</center>
			{/* <Footer /> */}
    </>
  );
}

export default PageNotFound;
